import React, { useEffect, useState } from 'react';
import { Modal, Button, notification } from 'antd';
import Grid from '@mui/material/Grid';
import 'antd/dist/antd.css'; 
import { Button as ButtonMui } from '@mui/material';

import Cookies from 'js-cookie';

const ModalInfo = ({handleClick}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const modalCookie = Cookies.get('showModalOnce');

    // Solo mostrar el modal si la cookie no está presente y si es menor a 2025
    if (!modalCookie && new Date().getFullYear() < 2025 && false) {
      const timer = setTimeout(() => {
        const key = `open${Date.now()}`;
        const btn = (
            <ButtonMui 
                variant="contained" 
                color="primary"
                onClick={(event)=> {
                    onClickButton(event, '#hoja')
                    notification.close(key)
                }}
            >Hoja de pedido</ButtonMui>
          );
        notification.info({
            message: (<h2>PRE VENTA 2025</h2>),
            description:(<h2>Ya puede descargar o llenar su hoja de pedido 2025</h2>),
            placement : 'top',
            btn,
            key,
            onClose: onClose,
            duration: 10,
        });
//        setIsModalVisible(true);
      }, 5000); // 5 segundos

      return () => clearTimeout(timer); // Limpiar el temporizador si el componente se desmonta
    }
  }, []);

  const onClose = () => {
    console.log('Notification was closed. Either the close button was clicked or duration time elapsed.');
  }

  const handleOk = () => {
    setIsModalVisible(false);
    // Establecer una cookie que expira en 7 días
    //Cookies.set('showModalOnce', 'true', { expires: 7 });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    // Establecer una cookie que expira en 7 días
    //Cookies.set('showModalOnce', 'true', { expires: 7 });
  };

  const onClickButton = (e, id) => {
    setIsModalVisible(false);
    handleClick(e, id);
  };

  return (
      <Modal
        title={<h3>Preventa 2025 ya disponible</h3>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cerrar
          </Button>,
        ]} 
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={12} textAlign={'center'}>
            <h3>Ya puede descargar o llenar su hoja de pedido 2025</h3>
            <ButtonMui 
                        variant="contained" 
                        color="primary"
                        size="large"
                        onClick={(event)=> onClickButton(event, '#hoja')}                        
                    >Hoja de pedido</ButtonMui>
          </Grid>
        </Grid>
      </Modal>
  );
};

export default ModalInfo;